import '../../styles/pages/spring-2020/digital-titan-mag.scss';

import React from "react";
import Layout from '../../components/layout';
import { Helmet } from "react-helmet"
import MoreStories from "../../components/spring-2020-stories/more-stories"
import StoryNav from "../../components/spring-2020-stories/story-nav"
import Vimeo from '@u-wave/react-vimeo';


import digitalTitanMag from '../../images/spring-2020/digital-titan-mag.jpg';

import socialBanner from "../../images/spring-2020/social-banners/digital-titan-mag.jpg";

import framSignature from "../../images/spring-2020/fram-signature.svg";



export default class Index extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    var pageTitle = 'Introducing the New Digital TITAN Magazine';
    var pageDesc = 'TITAN, the signature magazine of Cal State Fullerton, is proud to premiere its first fully digital issue. Here you will find bold and inspiring stories about the university’s people and programs — stories that will keep you connected to your CSUF family, demonstrate the impact of your support and make you feel proud to be a Titan. ';


    var heroStyles = {
      backgroundColor: '#222',
      backgroundImage: `url(${digitalTitanMag})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'top center',
      backgroundSize: 'cover',
      color: 'white'
    }


    return (
      <Layout headerStyle="white" locationInfo={this.props.location} issue="spring-2020">
        <Helmet>
          <title>{pageTitle}</title>
          <body className="digital-titan-mag" />

          <meta name="description" content={pageDesc} />

          {/* Schema.org markup for Google+ */}
          <meta itemprop="name" content={pageTitle} />
          <meta itemprop="description" content={pageDesc} />
          <meta itemprop="image" content={socialBanner} />

          {/* Twitter Card data */}
          <meta name="twitter:title" content={pageTitle} />
          <meta name="twitter:description" content={pageDesc} />
          <meta name="twitter:image:src" content={socialBanner} />

          {/* Open Graph data */}
          <meta property="og:title" content={pageTitle} />
          <meta property="og:image" content={socialBanner} />
          <meta property="og:image:alt" content={pageTitle} />
          <meta property="og:description" content={pageDesc} />
        </Helmet>
        <div className="hero story animated fadeIn" style={heroStyles}>
          <div className="text">
            <h1 className="animated fadeInDown delay-1s">{pageTitle}</h1>
          </div>
          <div className="tint"></div>
        </div>
        <article className="wrap small">

          <Vimeo
            className="animated fadeInDown align-wide video"
            video="418237201"
            responsive
            showTitle="false"
            showByline="false"
            showPortrait="false"
          />
        
          <p>TITAN, the signature magazine of Cal State Fullerton, is proud to premiere its first fully digital issue. Here you will find bold and inspiring stories about the university’s people and programs — stories that will keep you connected to your CSUF family, demonstrate the impact of your support and make you feel proud to be a Titan. </p>

          <h2>A Message From President Virjee</h2>

          <p>As Titans, we have long claimed to be the best in the nation at what we do. We use words like bold, brave, tenacious and tough. We use phrases like “University of Significance” and branding like “Titans Reach Higher” and “It Takes a Titan.”</p>

          <p>Our students live up to these bold assertions daily. They take care of their families. They transition from incarcerated individuals to honor roll college students. They support themselves and give back to our communities. They serve our nation as military leaders. They punch out after a long shift and run to class under the weight of both the books in their backpacks and the family legacies on their shoulders.</p>

          <p>Given the grit born from this Titan spirit, we knew that when this pandemic hit, our faculty, staff and students would find a way to not only ensure the health and safety of our campus community, but also uphold our mission to purvey equitable and academically rigorous paths to degree for all.</p>

          <p>Indeed, I am deeply appreciative and proud of what all Titans have done to continue innovating during uncertain times, all the while navigating their own pandemic-related struggles. In doing so, we quickly emerged as a system, state and national leader for educators and students navigating this pandemic. And with so many looking to us for inspiration, we recognized the responsibility to concurrently raise our communication bar to better share our best practices, student success and Titan spirit with the world.</p>

          <p>To that end, I am pleased to share the first fully digital issue of TITAN magazine. In it, you will find more examples of our university’s enterprising efforts to help students reach their fullest potential — from our world-renown Titan baseball team and four-peat Big West Champion softball team to our award-winning U-ACRE research experience and trailblazing Project Rebound program.</p>

          <p>I, as much as anyone (and, just maybe, more than anyone), look forward to the day we can all safely return to campus. Until then, I am thankful for these stories; not just for their impactful content, but also for the innovative way in which we are sharing them with the world.</p>

          <p>And if you are wondering what it takes to lead the world in both navigating this pandemic and inspiring others during this difficult time, the answer is on the cover of this digital magazine — It Takes a Titan. <span className="end-mark" /></p>

          <p style={{lineHeight: '1.2em'}}>
            Sincerely,<br /><br />
            <img src={framSignature} alt="Fram Virjee" width="200px" /><br />
            Fram Virjee<br />
            <small>President<br /></small>
          </p>

        </article>
        <MoreStories slug="digital-titan-mag" />
        <StoryNav slug="digital-titan-mag" />
      </Layout>
    );
  }
} 